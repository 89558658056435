import React from "react";
import SEO from "../../components/seo";
import HorizontalLine from "../../components/horizontal-line";
import Image from "../../components/image/image";
import {GetSimpleImage} from "../../helpers/get-simple-image";
import "../../assets/sass/articles.scss";

const CreateWebsitePl = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Jak stworzyć stronę" language="pl"/>
            <article className="article">
                <div className="container">
                    <div className="article__header">
                        <HorizontalLine classname="white"/>
                        <h1 className="article__header--title">Jak stworzyć własną stronę internetową?</h1>
                        <HorizontalLine classname="white"/>
                        <h2 className="article__header--subtitle">Stoisz przed wyzwaniem stworzenia strony firmowej dla
                            Twojego
                            przedsiębiorstwa? Chcesz
                            zrobić
                            stronę firmową, ale cały ten temat wydaje Ci się mocno problematyczny i nie bardzo wiesz od
                            czego zacząć? Już spieszymy z pomocą.</h2>
                    </div>
                    <Image image={GetSimpleImage("jak_stworzyc-strone.jpg")[0].node.fluid} alt="Jak stworzyć stronę"
                           classname="article__image--title"/>
                    <p className="article__text">
                        Stworzenie dobrej strony internetowej nie jest proste, przydatna jest wiedza z wielu dziedzin. W
                        tym artykule postaramy Ci się przybliżyć podstawowe zagadnienia, na które warto zwrócić uwagę
                        przy tworzeniu strony internetowej.
                    </p>
                    <h3 className="article__section--title text-center">Zacznij od strategii firmy</h3>
                    <p className="article__text">
                        Być może nie wydaje się to oczywistym pierwszym krokiem przy tworzeniu strony internetowej, ale
                        jest to krok, który jest często pomijany, choć bardzo istotny.<br/><br/>
                        <strong>Strona internetowa ma być przecież wizytówką firmy</strong>, musi więc być zgodna
                        wizerunkowo z samą firmą. Powinna wyrażać jej styl
                        i charakter, który określić możesz właśnie tworząc strategię.
                    </p>
                    <h3 className="article__section--title text-center">Określ grupy docelowe</h3>
                    <p className="article__text">
                        Tworząc strategię pewnie zastanowisz się nad tym do kogo tak naprawdę kierujesz swoją ofertę.
                        Zdefiniuj tych odbiorców. Zastanów się nad tym jakie grupy użytkowników są naprawdę Twoimi
                        potencjalnymi klientami. <br/><br/>
                        Pamiętaj by planować i projektować swoją stronę firmową tak, żeby mieć szansę trafić z przekazem
                        właśnie do użytkowników, do których chcesz trafić. To, że każdy może odwiedzić Twoją stronę nie
                        oznacza, że każdego musi przyciągać- ma przyciągać ludzi, na których zależy Twojej firmie.
                    </p>
                    <h3 className="article__section--title text-center">Określ cel</h3>
                    <p className="article__text">
                        Tym co warto zrobić planując tworzenie strony internetowej firmy jest oczywiście określenie jej
                        celu. Strona internetowa jest obecnie standardem, jednak warto wykorzystać jej możliwości. <br/><br/>
                        Pomyśl o tym w czym strona ma Ci pomóc? Czy ma jedynie opowiadać o ofercie Twojej firmy, czy
                        może budować konkretny wizerunek? Może ma Ci pomóc w pozyskiwaniu danych potencjalnych klientów
                        albo umożliwić użytkownikowi pobranie jakichś materiałów? Możliwości może być wiele! <br/><br/>
                        Pamiętaj! <strong>Cel możesz wyznaczyć nie tylko dla całej strony, ale też dla każdej
                        zaprojektowanej
                        podstrony.</strong>
                    </p>
                    <h3 className="article__section--title text-center">Pomyśl nad strukturą strony</h3>
                    <p className="article__text">
                        Po tym jak ustalisz cele strony możesz zastanowić się jak ma ona wyglądać, jakie podstrony ma
                        zawierać i w jakiej kolejności. <br/> <br/>
                        Określ jakie informacje chcesz zamieścić na stronie i zastanów się w jaki sposób chciałbyś je
                        wyeksponować. Na stronach firmowych często pojawiają się takie informacje jak:
                    </p>
                    <ul className="article__list">
                        <li>zakres świadczonych usług</li>
                        <li>kontakt</li>
                        <li>portfolio</li>
                        <li>cennik</li>
                    </ul>
                    <p className="article__text">
                        Możesz opracować mapę strony, która będzie niczym jej spis treści. Najlepiej rozrysować ją w
                        formie drzewa tak jak na przykładzie poniżej:
                    </p>
                    <Image image={GetSimpleImage("schemat.jpg")[0].node.fluid} alt="schemat"
                           classname="article__image--scheme"/>
                    <p className="article__text">
                        Pamiętaj by tworząc strukturę strony zastanowić się jednocześnie nad nawigacją na niej. Trzeba
                        ją zaplanować tak by poruszanie się po Twojej stronie było dla użytkownika intuicyjne.
                    </p>
                    <h3 className="article__section--title text-center">Przygotuj teksty na stronę</h3>
                    <h4 className="article__section--subtitle text-left">Godnie przedstaw swoją ofertę</h4>
                    <p className="article__text">
                        Tym co powinna zawierać Twoja strona internetowa jest na pewno jasny i klarowny opis Twojej
                        działalności. Nie opieraj się tylko na sloganach reklamowych, niech dobry slogan będzie
                        dopełnieniem dla konkretnej treści. <br/><br/>
                        Spójrz na ten temat z perspektywy potencjalnego klienta, czego Ty szukałbyś/szukałabyś na takiej
                        stronie jak strona Twojej firmy? Jakie informacje na temat działalności powinny się według
                        Ciebie tam znaleźć? <br/><br/>
                        Postaw na konkretne informacje, postaraj się by ta sekcja Twojej strony była naprawdę
                        precyzyjnie dopracowana. Pamiętaj, by teksty na Twojej stronie były unikalne, najlepiej własne.
                    </p>
                    <h4 className="article__section--subtitle text-left">Przedstaw treści w atrakcyjny sposób</h4>
                    <p className="article__text">
                        Zadbaj by treści na Twojej stronie nie były zbite w jednostajne obszerne teksty, które nie
                        zachęcają użytkownika do czytania. W dzisiejszych czasach treści na stronach są bardziej
                        przeglądane niż wnikliwie czytane. Zadbaj o:
                    </p>
                    <ul className="article__list">
                        <li>nagłówki</li>
                        <li>krótkie akapity</li>
                        <li>czytelne czcionki</li>
                        <li>separatory treści</li>
                        <li>stosowanie obrazów</li>
                    </ul>
                    <p className="article__text">
                        Dzięki temu przeglądanie treści na Twojej stronie firmowej będzie o wiele przyjemniejsze.
                    </p>
                    <h4 className="article__section--subtitle text-left">Postaraj się o atrakcyjne teksty</h4>
                    <p className="article__text">
                        Tworząc stronę zadbaj o to by treści nie były kopiowane z innych stron (jest to zakazane) i były
                        interesujące. Unikalne treści na stronie to podstawa. Zadbaj by użyty w tekstach język był
                        zrozumiały dla odbiorcy.
                    </p>
                    <h3 className="article__section--title text-center">Ułatw kontakt</h3>
                    <p className="article__text">
                        Chcesz, by Twoi klienci się z Toba kontaktowali? Postaraj się im to ułatwić. Zadbaj by na Twojej
                        stronie firmowej znalazły się potrzebne dane do kontaktu takie jak nr telefonu, e-mail czy też
                        adres firmy. <br/><br/>
                        Ważne jest by łatwo było odnaleźć tą sekcję na stronie. Ponadto możesz zastosować też formularz
                        kontaktowy, który znacznie ułatwi użytkownikowi napisanie maila do Ciebie.
                    </p>
                    <h3 className="article__section--title text-center">Teraz trzy podstawy, o które trzeba zadbać.</h3>
                    <p className="article__text">
                        Wiemy, że te wszystkie fachowe określenia są zmorą większości ludzkości, ale jeśli chcesz
                        stworzyć stronę internetową, powinieneś bezwzględnie znać chociaż te trzy z nich:
                    </p>
                    <ul className="article__list">
                        <li><strong>Domena</strong> - to najprościej rzecz ujmując adres Twojej strony w internecie, np.
                            mojafirma.pl, warto dobrze przemyśleć ten wybór.
                        </li>
                        <li><strong>Hosting</strong> - usługa polegająca na udostępnianiu miejsca na serwerze. Aby Twoja
                            strona mogła działać musi być obsługiwana przez serwer, na którym znajdować się będą
                            wszystkie pliki, z których się składa. Nie wybieraj hostingu pochopnie, warto zapoznać się z
                            ofertą na rynku i opiniami. Serwer, na którym stać będzie Twoja strona powinien zapewnić jej
                            odpowiednią szybkość działania i gwarantować działanie nawet przy dużej liczbie odwiedzin
                            strony jednocześnie.
                        </li>
                        <li><strong>Certyfikat SSL</strong> - jest to technologia, która polega na szyfrowaniu danych
                            przepływających drogą elektroniczną, od serwera począwszy na użytkowniku kończąc. Certyfikat
                            ma zabezpieczać informacje przed przechwyceniem i podwyższać wiarygodność strony.
                        </li>
                    </ul>
                    <h3 className="article__section--title text-center">Zadbaj o CMS</h3>
                    <p className="article__text">
                        <strong>CMS</strong> - czyli system zarządzania treścią jest mechanizmem, który umożliwia
                        samodzielną edycję strony. Panel zarządzania treścią pozwala na wprowadzanie aktualności i
                        dodawanie treści bez konieczności znajomości języków programowania. <br/> <br/>
                        Chcąc stworzyć swoją stronę firmową masz do wyboru kilka rozwiązań, m. in:
                    </p>
                    <ul className="article__list">
                        <li>
                            Skorzystanie z gotowych rozwiązań (komercyjnych bądź darmowych)
                        </li>
                        <li>
                            Wynajęcie firmy, która stworzy CMS od zera, dostosowując go do Twoich potrzeb
                        </li>
                    </ul>
                    <p className="article__text">
                        Oczywiście stworzenie systemu zarządzania treścią od zera jest trochę droższym i bardziej
                        czasochłonnym rozwiązaniem, ale za to jest to system „szyty na miarę” i dostosowany do Twoich
                        potrzeb. <br/> <br/>
                        Do wyboru są też gotowe rozwiązania. Może to być system komercyjny lub darmowy. Tutaj też
                        pojawiają Ci się dwie możliwości.
                    </p>
                    <ul className="article__list">
                        <li>
                            samodzielna obsługa gotowych systemów
                        </li>
                        <li>
                            wynajęcie firmy, która się tym zajmie
                        </li>
                    </ul>
                    <p className="article__text">
                        Opcja pierwsza, która wydaje się naprawdę kusząca z racji na najniższe koszty nie jest jednak
                        opcją idealną. Jak w większości darmowych rozwiązań, wiąże się ona z wieloma ograniczeniami. O
                        ile nie jest to uciążliwe na samym początku o tyle później może generować nowe problemy i
                        koszty. <br/> <br/>
                        Opcja druga wydaje się być rozwiązaniem optymalnym. Wynajęcie firmy, która dostosuje gotowy CMS
                        do naszych potrzeb jest tańsze niż tworzenie go od zera i zapewnia większą elastyczność w
                        tworzeniu strony. <br/> <br/>
                        <strong>Zastanów się więc, która opcja jest dla Ciebie najbardziej korzystna i działaj!</strong>
                    </p>
                    <blockquote>
                        <p>
                            Na początek tyle informacji powinno wystarczyć. Oczywiście jest to tylko ułamek tego co
                            można napisać o tym jak stworzyć stronę internetową. W kolejnych artykułach będziemy szerzej
                            opisywać niektóre z wyżej wymienionych aspektów oraz poszerzać tą listę o kolejne punkty.
                            Mamy nadzieję, że artykuł był pomocny.
                        </p>
                    </blockquote>
                </div>
            </article>
        </div>
    );
};

export default CreateWebsitePl;